export const providers = [
  'pragmatic',
  'netent',
  'playngo',
  'evoplay',
  'gameart',
  'platipus',
  'betsoft',
  'amatic',
  'wazdan',
  'playson',
  'quickspin',
  'bsw',
  'playtech',
  'dlv',
  'fishing',
  'endorphina',
  'merkur',
  'kajot',
  'wacs',
  'gaminator',
  'mrslotty',
  'igt',
  'greentube',
  'aristocrat',
  'apollo',
  'inbet',
  'konami',
  'austria',
  'flg',
  'cqgaming',
  'relaxgaming',
  'booongo',
  'igrosoft',
  'nolimit',
  'wmg',
  'elbet',
  'redrake',
  'spribe',
  'pragmaticlive',
  'evolutionlive',
  'pragmaticlive',
  'tvbet',
  'belatra',
  'xpg',
  'goldenrace',
];

export const daysFilterValue = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
  {
    text: 'lastMonth',
    value: -31,
  },
];

export const daysFilterValueMobile = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
];

export const structure = [
  {
    name: 'allUsers',
    value: 'allUsers',
  },
  {
    name: 'directOnly',
    value: 'directOnly',
  },
];

export const searchInItems = [
  {
    name: 'agent',
    value: '6',
  },
  {
    name: 'user',
    value: '0',
  },
];

export const providerItems = [
  {
    name: 'all',
    value: '',
  },
  {
    name: 'PRAGMATIC-SLOTS',
    value: 'PRAGMATIC',
  },
  {
    name: 'PRAGMATIC-LIVE',
    value: 'PRAGMATIC',
  },
  {
    name: 'OUTCOMEBET',
    value: 'OUTCOMEBET',
  },
  {
    name: 'EVOPLAY',
    value: 'EVOPLAY',
  },
  {
    name: 'BELATRA',
    value: 'BELATRA',
  },
  // {
  //   name: 'EVOLUTION',
  //   value: 'EVOLUTION',
  // },
  {
    name: 'XPG',
    value: 'XPG',
  },
  {
    name: 'TVBET',
    value: 'TVBET',
  },
  {
    name: 'FIABLE',
    value: 'FIABLE',
  },
  {
    name: 'BSWGAMES',
    value: 'BSWGAMES',
  },
  {
    name: 'INBET',
    value: 'INBET',
  },
  {
    name: 'SOFTGAMING',
    value: 'SOFTGAMING',
  },
  {
    name: 'CASIMI',
    value: 'CASIMI',
  },
  {
    name: 'SLOTEGRATOR',
    value: 'SLOTEGRATOR',
  },
  {
    name: 'RUBYPLAY',
    value: 'RUBYPLAY',
  },
  {
    name: 'GOLDENRACE',
    value: 'GOLDENRACE',
  },
  {
    name: 'FLG',
    value: 'FLG',
  },
];

export const statusBets = [
  {
    name: 'win',
    value: 1,
  },
  {
    name: 'bet',
    value: 2,
  },
  {
    name: 'refund',
    value: 3,
  },
];

export const statusSportBets = [
  {
    name: 'All',
    value: 'All',
  },
  {
    name: 'Won',
    value: 'WON',
  },
  {
    name: 'Cashed out',
    value: 'CASHED_OUT',
  },
  {
    name: 'Returned',
    value: 'RETURNED',
  },
  {
    name: 'Lost',
    value: 'LOST',
  },
  {
    name: 'Accepted',
    value: 'ACCEPTED',
  },
];

export const gameTypes = [
  {
    name: 'slots',
    value: 'slots',
  },
  {
    name: 'liveCasino',
    value: 'live-casino',
  },
  {
    name: 'fiable',
    value: 'fiable',
  },
  {
    name: 'all',
    value: 'all',
  },
];

export const typesSportBets = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'Single',
    value: 'single',
  },
  {
    name: 'Express',
    value: 'express',
  },
];
