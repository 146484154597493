import changePasswordSVG from '../img/change-password.svg';
import disableSVG from '../img/disable.svg';
import editSVG from '../img/edit.svg';
import informationsSVG from '../img/informations.svg';
import agentSVG from '../img/agent.svg';
import playerSVG from '../img/player.svg';
import lastMovementsSVG from '../img/last-movements.svg';
import changePermissionsSVG from '../img/change-permissions.svg';

export const userTypes = {
  0: {
    icon: playerSVG,
    type: 'player',
  },
  6: {
    icon: agentSVG,
    type: 'agent',
  },
};

export const actionItems = {
  forEnabled: [
    {
      svg: informationsSVG,
      title: 'informations',
      link: '/user/info',
    },
    {
      svg: editSVG,
      title: 'edit',
      link: '/user/edit',
    },
    {
      svg: changePasswordSVG,
      title: 'changePassword',
      link: '/user/change-password',
    },
    {
      svg: lastMovementsSVG,
      title: 'lastMovements',
    },
    {
      svg: disableSVG,
      title: 'disable',
      link: '/user/disable',
    },
    {
      svg: changePermissionsSVG,
      title: 'changePermissions',
    },
  ],
  forDisabled: [
    {
      svg: disableSVG,
      title: 'enable',
      link: '/user/enable',
    },
  ],
};

export const getActionItems = ({ role, isBanned }) => {
  if (isBanned) {
    return [
      {
        svg: disableSVG,
        title: 'enable',
        link: '/user/enable',
      },
    ];
  }
  if (+role === 0) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      {
        svg: editSVG,
        title: 'edit',
        link: '/user/edit',
      },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: changePermissionsSVG,
        title: 'permissionsDetails',
        link: '/user/permissionsDetails',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
  if (+role === 6) {
    return [
      {
        svg: informationsSVG,
        title: 'informations',
        link: '/user/info',
      },
      {
        svg: editSVG,
        title: 'edit',
        link: '/user/edit',
      },
      {
        svg: changePasswordSVG,
        title: 'changePassword',
        link: '/user/change-password',
      },
      {
        svg: changePermissionsSVG,
        title: 'permissionsDetails',
        link: '/user/permissionsDetails',
      },
      {
        svg: lastMovementsSVG,
        title: 'lastMovements',
      },
      {
        svg: disableSVG,
        title: 'disable',
        link: '/user/disable',
      },
    ];
  }
};

export const permissionsDetailsData = [
  {
    category: 'casino',
    items: [
      'evoplay',
      'slotegrator',
      'softgaming-slots',
      'outcome',
      'fiable',
      'rubiplay',
      'casimi',
      'bswgames',
      'flg',
      'goldenrace',
      'pragmatic',
      'inbet',
      'belatra',
    ],
  },
  {
    category: 'liveCasino',
    items: ['tvbet', 'xpg'],
  },
  { category: 'sport', items: ['Digitain'] },
];

export const amountForAutoInput = [100, 500, 1000, 5000];
